import React, { FunctionComponent, useRef, useEffect, useState } from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { gsap } from "gsap";
import { PortableText } from "../../types/SanityTypes";
import "./styles.scss";
import { VideoPause } from "../../images/icons/videoPause";
import { VideoPlay } from "../../images/icons/videoPlay";
import { createThemeClassName } from "../../utils/themeClass";

export interface TickerTextInterface {
  headings: {
    primaryText: string;
    secondaryText: string;
  };
  _rawText: PortableText;
  theme: {
    name: string;
  };
  // For Preview
  text?: PortableText;
}

const TickerText: FunctionComponent<TickerTextInterface> = ({ headings, _rawText, text, theme }) => {
  const ticker1 = useRef(null);
  const ticker2 = useRef(null);
  const mediaQuery = (typeof window !== "undefined" && window.matchMedia("(prefers-reduced-motion: reduce)")) || false;
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [userHasScrolled, setUserHasScrolled] = useState<boolean>(false);

  useEffect(() => {
    if (!isPlaying) {
      handleTickerAnimation(ticker1.current, 0, 0);
      handleTickerAnimation(ticker2.current, 0, 0);
    }
    if (!mediaQuery.matches && isPlaying) {
      handleTickerAnimation(ticker1.current, -1230, 0);
      handleTickerAnimation(ticker2.current, 0, -1230);
    }
    mediaQuery.addEventListener("change", () => {
      if (!mediaQuery.matches && isPlaying) {
        handleTickerAnimation(ticker1.current, -1230, 0);
        handleTickerAnimation(ticker2.current, 0, -1230);
      }
    });
  }, [ticker1, ticker2, isPlaying]);

  useEffect(() => {
    const handleScroll = () => {
      if (!userHasScrolled) {
        setUserHasScrolled(true);
        setIsPlaying(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [userHasScrolled]);

  function handleTickerAnimation(element: any, start: number, end: number) {
    gsap.fromTo(element, { x: start }, { x: end, ease: "none", repeat: -1, duration: 20 });
  }

  const textBody = _rawText || text;

  return (
    <section className={`moving-text ${createThemeClassName(theme?.name)}`}>
      <button
        data-play="Play"
        data-pause="Pause"
        onClick={() => setIsPlaying(!isPlaying)}
        className={"btn-playPause"}
        type="button"
        aria-label="Play/Pause scrolling text"
      >
        {isPlaying ? <VideoPause /> : <VideoPlay />}
      </button>
      <div className="ticker row1" aria-hidden="true" ref={ticker1}>
        {headings.primaryText} <span>{headings.secondaryText}</span> {headings.primaryText}{" "}
        <span>{headings.secondaryText}</span> {headings.primaryText} <span>{headings.secondaryText}</span>{" "}
        {headings.primaryText} <span>{headings.secondaryText}</span>
      </div>

      <div className="ticker row2" aria-hidden="true" ref={ticker2}>
        <span>{headings.primaryText}</span> {headings.secondaryText} <span>{headings.primaryText}</span>{" "}
        {headings.secondaryText} <span>{headings.primaryText}</span> {headings.secondaryText}{" "}
        <span>{headings.primaryText}</span> {headings.secondaryText}
      </div>
      <h2 className="sr-only">
        {headings.primaryText} {headings.secondaryText}
      </h2>

      {/* IF there is ticker copy... */}

      <Container fluid>
        <Row>
          <Col lg={{ span: 6, offset: 1 }} sm={{ span: 10, offset: 2 }}>
            {/* divider needs theme color */}
            <hr className="divider" />
            <div className="ticker-copy">{textBody && <BlockContent blocks={textBody} />}</div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TickerText;
